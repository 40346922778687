import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Cookies from 'js-cookie'
import publicIp from 'public-ip'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'
import Select from 'react-select'

// components
import StyledButton from './components/Button'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Content-Type'] = 'application/json'

const PORTAL_ID = 5217373
const FORM_ID = '696da0f0-a69f-41c5-92b6-75e7011440e4'

const schema = Yup.object({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  company: Yup.string().required('Company name is required'),
  email: Yup.string()
    .email()
    .required('Email is required'),
  inquiry: Yup.string().required('Inquiry is required'),
  recaptcha: Yup.string().required('Recaptcha is required')
})

const SUCCESS = 'success'
const INFO = 'info'
const ERROR = 'danger'
let tmr = null

const DescribeHome = styled(({ className }) => {
  const [recaptcha, setRecaptcha] = useState(false)
  const [ipAddress, setIpAddress] = useState('')
  const [selectedOption, setSelectedOption] = useState('')

  const [message, setMessage] = useState({
    type: SUCCESS,
    text: '',
    show: false
  })

  const getClientIp = async () => {
    const ip = await publicIp.v4()
    setIpAddress(ip)
  }

  const options = [
    { value: 'Pricing/Sales', label: 'Pricing/Sales' },
    { value: 'Billing', label: 'Billing' },
    { value: 'Marketing', label: 'Marketing' },
    { value: 'Partnerships', label: 'Partnerships' },
    { value: 'Employment', label: 'Employment' },
    { value: 'Other', label: 'Other' }
  ]

  useEffect(() => {
    return () => hideMessage(tmr)
  }, [])

  useEffect(() => {
    getClientIp()
  }, [ipAddress])

  function hideMessage() {
    clearTimeout(tmr)
    setMessage({
      show: false
    })
  }
  function showMessage(text, type = INFO) {
    setMessage({
      type,
      text,
      show: true
    })
    tmr = setTimeout(() => {
      hideMessage()
    }, 8000)
  }

  const handleFormikSubmit = (
    model,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    showMessage('Sending message...')

    const { firstname, lastname, company, email, inquiry, message } = model

    const isBrowser = typeof window !== 'undefined'
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null
    const pageUri = isBrowser ? window.location.href : null
    const pageName = isBrowser ? document.title : null
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`

    const body = {
      fields: [
        {
          name: 'firstname',
          value: firstname
        },
        {
          name: 'lastname',
          value: lastname
        },
        {
          name: 'your_company',
          value: company
        },
        {
          name: 'email',
          value: email
        },
        {
          name: 'inquiry',
          value: inquiry
        },

        {
          name: 'message',
          value: message
        }
      ],
      context: {
        hutk,
        pageUri,
        pageName,
        ipAddress
      }
    }

    axios
      .post(postUrl, body)
      .then(res => {
        if (res.status === 200) {
          resetForm()
          setSelectedOption('')
          setRecaptcha(false)
          window.grecaptcha.reset()
          showMessage('Message successfully sent!')
        }
      })
      .catch(err => {
        setSubmitting(false)
        if (err) {
          showMessage(
            'Sorry, but something went wrong. Please try again',
            ERROR
          )
          return console.error(err)
        }
      })
  }

  function verifyCallback(value) {
    if (value != '') {
      setRecaptcha(true)
    }
  }

  function onExpired() {
    setRecaptcha(false)
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleFormikSubmit}
      initialValues={{
        firstname: '',
        lastname: '',
        company: '',
        email: '',
        inquiry: '',
        message: '',
        recaptcha: ''
      }}
      render={({
        handleSubmit,
        setFieldValue,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        isSubmitting,
        errors
      }) => (
        <Form className={className} noValidate onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label
                  className="text-white"
                  htmlFor="hubspot-form-firstname"
                >
                  First Name <span className="invalid-helper d-none">*</span>
                </Form.Label>
                <div className="from-input-item">
                  <Form.Control
                    type="text"
                    name="firstname"
                    id="hubspot-form-firstname"
                    value={values.firstname}
                    onChange={handleChange}
                    isValid={touched.firstname && !errors.firstname}
                    className={
                      errors.firstname && touched.firstname ? 'error' : null
                    }
                    placeholder={
                      errors.firstname && touched.firstname
                        ? errors.firstname
                        : ''
                    }
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label
                  className="text-white"
                  htmlFor="hubspot-form-lastname"
                >
                  Last Name <span className="invalid-helper d-none">*</span>
                </Form.Label>
                <div className="from-input-item">
                  <Form.Control
                    type="text"
                    name="lastname"
                    id="hubspot-form-lastname"
                    value={values.lastname}
                    onChange={handleChange}
                    isValid={touched.lastname && !errors.lastname}
                    className={
                      errors.lastname && touched.lastname ? 'error' : null
                    }
                    placeholder={
                      errors.lastname && touched.lastname ? errors.lastname : ''
                    }
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label className="text-white" htmlFor="hubspot-form-company">
              Company <span className="invalid-helper d-none">*</span>
            </Form.Label>
            <div className="from-input-item">
              <Form.Control
                type="text"
                name="company"
                id="hubspot-form-company"
                value={values.company}
                onChange={handleChange}
                isValid={touched.company && !errors.company}
                className={errors.company && touched.company ? 'error' : null}
                placeholder={
                  errors.company && touched.company ? errors.company : null
                }
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label className="text-white" htmlFor="hubspot-form-email">
              Email <span className="invalid-helper d-none">*</span>
            </Form.Label>
            <div className="from-input-item">
              <Form.Control
                type="text"
                name="email"
                id="hubspot-form-email"
                value={values.email}
                onChange={handleChange}
                isValid={touched.email && !errors.email}
                className={errors.email && touched.email ? 'error' : null}
                placeholder={
                  errors.email && touched.email ? errors.email : 'mail@mail.com'
                }
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label className="text-white" htmlFor="hubspot-form-inquiry">
              Inquiry <span className="invalid-helper d-none">*</span>
            </Form.Label>
            <div className="from-input-item">
              <div className="form-select">
                <Select
                  name="inquiry"
                  className="inquiry-select"
                  styles={{
                    placeholder: () => ({
                      color:
                        errors.inquiry && touched.inquiry
                          ? '#E10506'
                          : '#000000'
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        backgroundColor:
                          isSelected || isFocused
                            ? 'rgba(245, 193, 65, 0.3)'
                            : data.color,
                        color: '#000000',
                        ':active': {
                          ...styles[':active'],
                          backgroundColor:
                            isSelected || isFocused
                              ? 'rgba(245, 193, 65, 0.3)'
                              : data.color
                        }
                      }
                    },
                    valueContainer: styles => ({
                      ...styles,
                      padding: '14px 20px'
                    }),
                    indicatorSeparator: styles => ({
                      ...styles,
                      display: 'none'
                    }),
                    menu: styles => ({
                      ...styles,
                      height: 'inherit',
                      overflow: 'visible'
                    })
                  }}
                  value={selectedOption}
                  onChange={option => {
                    setFieldValue('inquiry', option.value)
                    setSelectedOption(option)
                  }}
                  options={options}
                  placeholder={
                    errors.inquiry && touched.inquiry
                      ? errors.inquiry
                      : 'Select one'
                  }
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                />
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label className="text-white" htmlFor="hubspot-form-message">
              Describe Your Needs - Optional{' '}
              <span className="invalid-helper d-none">*</span>
            </Form.Label>
            <div className="from-input-item">
              <Form.Control
                as="textarea"
                rows="3"
                name="message"
                id="hubspot-form-message"
                value={values.message}
                onChange={handleChange}
                isValid={touched.message && !errors.message}
                className={errors.message && touched.message ? 'error' : null}
                placeholder={
                  errors.message && touched.message ? errors.message : 'Message'
                }
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-0 mt-0">
            {/* <StyledButton disabled={!isValid || isSubmitting} type="submit"> */}
            <ReCAPTCHA
              sitekey="6LdW6WQbAAAAAG_t7gfCkO8QJZHLqVw4G5DmQ2Tc"
              onExpired={onExpired}
              className={`ReCAPTCHA ${
                !recaptcha && errors.recaptcha && touched.recaptcha
                  ? 'mb-0'
                  : ''
              }`}
              onChange={value => {
                verifyCallback(value)
                setFieldValue('recaptcha', value)
              }}
            />
            {!recaptcha && errors.recaptcha && touched.recaptcha ? (
              <p className="invalid-helper">{errors.recaptcha}</p>
            ) : null}
            <StyledButton
              type="submit"
              className={recaptcha === false ? 'disabled' : null}
              disabled={recaptcha === false ? true : false}
            >
              {isSubmitting ? 'Sending...' : 'Send'}
            </StyledButton>
          </Form.Group>
          {message.show && (
            <Form.Group style={{ marginTop: '1rem' }}>
              <Alert variant={message.type}>{message.text}</Alert>
            </Form.Group>
          )}
        </Form>
      )}
    />
  )
})`
  input,
  textarea {
    &.is-valid {
      background-image: none;
    }
    &.error {
      &::placeholder {
        color: #e10506;
      }
    }
  }
  .invalid-helper {
    font-size: 16px;
    color: #e10506 !important;
    line-height: 24px;
    margin-top: 1rem;
  }
`

export default DescribeHome
