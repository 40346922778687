import styled from "@emotion/styled"
import Button from "react-bootstrap/Button"

import colors from "@styles/js/colors"

const StyledButton = styled(Button)`
  background-color: ${colors.saffron};
  color: ${colors.black};
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: ${colors.gamboge};
  }

  @media (min-width: 768px) {
    width: auto;
  }

  &.disabled{
    cursor: not-allowed;
    user-select: none;
  }
`

export default StyledButton
